import { FC } from "react";

import { chakra, HStack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";

import { Button, IconButton } from "@components/buttons";
import Dropdown from "@components/Dropdown";
import Icon from "@components/Icon";
import LanguageSelect from "@components/LanguageSelect";
import { LinkWrapper } from "@components/Link";
import { Mobile, Desktop } from "@lib/media";
import { useRouter } from "@lib/router";
import { ROUTES } from "@utils/constants";

import useLocaleList from "../../hooks/useLocaleList";

const LoggedOutWidget: FC = () => {
  const { asPath } = useRouter();
  const { t } = useTranslation();
  const { availableLanguages, localeValue } = useLocaleList();
  const LogoutIcon = <Icon icon="logout" size="1.5rem" />;

  return (
    <>
      <Mobile>
        <HStack spacing="0.625rem">
          <LinkWrapper href={ROUTES.LOGIN}>
            <IconButton
              id="login-button-mobile"
              variant="grey-light"
              size="md"
              iconSpacing="0.5"
              icon={LogoutIcon}
              title={t("layout-header-login")}
              as="a"
            />
          </LinkWrapper>
          <Dropdown id="language-dropdown" options={availableLanguages} value={localeValue} href={asPath} />
        </HStack>
      </Mobile>
      <Desktop>
        <HStack spacing="2rem">
          <LinkWrapper href={ROUTES.LOGIN}>
            <Button
              id="login-button-desktop"
              variant="grey-light"
              size="md"
              iconSpacing="0.5rem"
              label={<chakra.span pt="0.125rem">{t("layout-header-login")}</chakra.span>}
              rightIcon={LogoutIcon}
              as="a"
            />
          </LinkWrapper>
          <LanguageSelect options={availableLanguages} value={localeValue} href={asPath} />
        </HStack>
      </Desktop>
    </>
  );
};

export default LoggedOutWidget;
