/* eslint-disable @next/next/no-img-element */
import { FC } from "react";

const DpdGroupLogo: FC = () => (
  <img
    src="/assets/images/geopost.svg"
    alt="DPD Group"
    title="DPD Group"
    width="115px"
    height="30px"
    decoding="async"
    loading="eager"
  />
);

export default DpdGroupLogo;
