import { cloneElement, FC, isValidElement } from "react";

import Link from "next/link";

import { EmptyWrapper } from "@components/common";

import type { TNextLinkWrapperProps, TExternalLinkWrapperProps, TLinkWrapperProps } from "./types";

const NextLinkWrapper: FC<TNextLinkWrapperProps> = ({ children, href, ...rest }) => (
  <Link
    /* This is always true, because when "href ==== undefined" EmptyWrapper is rendered instead. */
    href={href!}
    passHref
    prefetch={false}
    {...rest}
    legacyBehavior>
    {children}
  </Link>
);

const ExternalLinkWrapper: FC<TExternalLinkWrapperProps> = ({ children, target, ...rest }) =>
  cloneElement(children, {
    ...rest,
    ...(target ? { target, rel: "alternate" } : { target: "_blank", rel: "noreferrer" }),
  });

const LinkWrapper: FC<TLinkWrapperProps> = ({ children, href, isExternal, anchorComponent, ...rest }) => {
  if (!href) {
    return <EmptyWrapper>{children}</EmptyWrapper>;
  }

  const wrappedChildren = isValidElement(anchorComponent) ? cloneElement(anchorComponent, {}, children) : children;

  if (isExternal) {
    return (
      <ExternalLinkWrapper href={href} {...rest}>
        {wrappedChildren}
      </ExternalLinkWrapper>
    );
  }

  return (
    <NextLinkWrapper href={href} {...rest}>
      {wrappedChildren}
    </NextLinkWrapper>
  );
};

export default LinkWrapper;
