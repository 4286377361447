import Cookies from "js-cookie";

import { COOKIE_CONSENT } from "@modules/features";
import { TFeatures } from "@modules/features/types";

import { CONSENT_COOKIE, GTM_EVENT } from "./constants";

export const checkIfConsentCookieExists = (): boolean => !!Cookies.get(CONSENT_COOKIE);

export const getConsentCookie = (): string | undefined => Cookies.get(CONSENT_COOKIE);

export const setConsentCookie = (value: boolean | string): void => {
  const expires = new Date();
  expires.setFullYear(expires.getFullYear() + 1);
  Cookies.set(CONSENT_COOKIE, value.toString(), { expires, sameSite: "strict" });

  //  Fire GTM event if dataLayer is found
  if ((window as any).dataLayer) {
    (window as any).dataLayer.push({ event: GTM_EVENT });
  }
};

export const checkIfCookieFeatureEnabled = (features: TFeatures): boolean => features?.includes(COOKIE_CONSENT);
