/* eslint-disable @next/next/no-img-element */
import { FC } from "react";

const DpdLogoLT: FC = () => (
  <img
    src="/assets/images/DPD_esiunta_logo.svg"
    title="DPD"
    alt="DPD"
    width="164px"
    height="36px"
    decoding="async"
    loading="eager"
  />
);

export default DpdLogoLT;
