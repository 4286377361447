import { FC } from "react";

import { Container } from "@chakra-ui/react";

import { TLayoutContentProps } from "./types";

const LayoutContent: FC<TLayoutContentProps> = ({ children, sx }) => (
  <Container maxWidth="71.25rem" paddingX="0.5rem" sx={sx}>
    {children}
  </Container>
);

export default LayoutContent;
