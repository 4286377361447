import { FC } from "react";

import { Box, HStack, useMultiStyleConfig } from "@chakra-ui/react";

import { Typography } from "@components/common";
import Link from "@components/Link";

import { TLanguageSelectProps } from "./types";

const getKey = (label: string, index: number): string => `language-select-${label}-${index}`;
const LanguageSelect: FC<TLanguageSelectProps> = ({ options, href = "/", value }) => {
  const styles = useMultiStyleConfig("LanguageSelect", {});
  return (
    <HStack spacing={0}>
      {options.map(({ label, value: optionValue }, index) => (
        <Box key={getKey(label, index)} position="relative">
          {optionValue === value && <Box sx={styles.marker} />}
          <Link
            id={`language-select-${optionValue}`}
            label={<Typography sx={styles.linkText}>{label.toUpperCase()}</Typography>}
            fontWeight={optionValue === value ? "normal" : "light"}
            variant={optionValue === value ? "red" : "dark"}
            sx={styles.link}
            name={`language-select-${optionValue}`}
            href={`/${optionValue}${href}`}
            target="_self"
            isExternal
          />
        </Box>
      ))}
    </HStack>
  );
};

export default LanguageSelect;
