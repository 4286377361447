import { FC } from "react";

import getConfig from "../config";
import useCookieGroups from "../hooks/useCookieGroups";
import InstantCookieModal from "./InstantCookieModal";
import SettingsCookieModal from "./SettingsCookieModal";

type TCookieModalsProps = {
  isSettingsModalOpen: boolean;
  onSettingsModalClose: () => void;
};

const CookieModals: FC<TCookieModalsProps> = ({ isSettingsModalOpen, onSettingsModalClose }) => {
  const cookieOptions = getConfig();
  const { onChange, selectedCookies } = useCookieGroups(cookieOptions);

  return (
    <>
      <InstantCookieModal onChange={onChange} selectedCookies={selectedCookies} />
      <SettingsCookieModal
        isOpen={isSettingsModalOpen}
        onClose={onSettingsModalClose}
        onChange={onChange}
        selectedCookies={selectedCookies}
      />
    </>
  );
};

export default CookieModals;
