import { CSSWithMultiValues, RecursiveCSSObject, SystemProps, CSSObject } from "@chakra-ui/react";

type TStyles = Record<string, RecursiveCSSObject<CSSWithMultiValues>>;
export const getModalFooterStyles = (
  styles: TStyles,
  hasSubmitButton: boolean,
  hasRejectButton: boolean,
  isCentered: boolean
): CSSObject => ({
  ...styles.footer,
  ...(isCentered && (hasSubmitButton || hasRejectButton) && { justifyContent: "center" }),
  ...(hasSubmitButton && hasRejectButton && { justifyContent: "space-between" }),
});
export const getModalContentStyles = (styles: TStyles, width: SystemProps["width"]): CSSObject => ({
  ...styles.modal,
  ...(width && { width }),
});
export const getModalHeaderWrapperStyles = (styles: TStyles, hasHeader: boolean): CSSObject => ({
  ...styles.headerWrapper,
  ...(!hasHeader && { position: "absolute", right: "0" }),
});
export const getModalHeaderStyles = (styles: TStyles, isCentered: boolean, hasCloseButton: boolean): CSSObject => ({
  ...styles.header,
  ...(isCentered && { textAlign: "center", marginLeft: hasCloseButton ? "2rem" : 0, flexShrink: 1 }),
});
export const getModalBodyStyles = (
  styles: Record<string, RecursiveCSSObject<CSSWithMultiValues>>,
  isCentered: boolean,
  hasCloseButton: boolean,
  hasHeader?: boolean
): CSSObject => ({
  ...styles.body,
  ...(hasCloseButton &&
    !hasHeader && {
      _after: {
        display: "block",
        content: '""',
        width: "1.5rem",
        height: "1.5rem",
        flexShrink: 0,
      },
    }),
  ...(isCentered && { display: "flex", justifyContent: "center" }),
});
