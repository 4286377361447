import { TDropdownOption } from "../types";

type TOptionValues = {
  selectedOption: TDropdownOption;
  remainingOptions: TDropdownOption[];
};

function getOptionValues(options: TDropdownOption[], value: string): TOptionValues {
  const optionIndex = options.findIndex(({ value: optionValue }) => optionValue === value);
  if (optionIndex === -1) {
    throw new Error(`Dropdown value "${value}" was not found`);
  }

  const remainingOptions = [...options.slice(0, optionIndex), ...options.slice(optionIndex + 1)];
  const selectedOption = options[optionIndex];
  return { selectedOption, remainingOptions };
}

export default getOptionValues;
