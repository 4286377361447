import {FC, useEffect, useState} from "react";

import { checkIfConsentCookieExists } from "../utils/cookieActions";
import CookieConsentModal from "./CookieConsentModal";

type TProps = {
  onChange: (value: string | boolean) => void;
  selectedCookies: string | boolean;
};

const InstantCookieModal: FC<TProps> = ({ onChange, selectedCookies }) => {
  const isCookieConsentExists = checkIfConsentCookieExists();
  const [isOpen, setIsOpen] = useState<boolean | null | undefined>(null);

  useEffect(() => {
    setIsOpen(!isCookieConsentExists);
  }, []);

  return (
      <>
        {isOpen && <CookieConsentModal
            onChange={onChange}
            selectedCookies={selectedCookies}
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            id="instant-cookie-modal"
          />}
      </>
  );
};

export default InstantCookieModal;
