import { useCallback, useEffect } from "react";

import { useRouter } from "@lib/router";

import getFbPixelId from "./getFbPixelId";

const useFbPixel = (): void => {
  const { defaultLocale } = useRouter();

  const initFBPixel = useCallback(async () => {
    const fbPixelId = getFbPixelId(defaultLocale);

    if (!fbPixelId) {
      return;
    }
    const ReactPixel = (await import("react-facebook-pixel")).default;

    ReactPixel.init(fbPixelId);
  }, [defaultLocale]);

  useEffect(() => {
    initFBPixel();
  }, [initFBPixel]);
};
export default useFbPixel;
