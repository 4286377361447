import { FC, memo } from "react";

import FieldWrapper from "./FieldWrapper";
import { TFormikFieldRequiredProps, TWrappedField } from "./types";
/*
 * For the Input component to be eligible for the use of this function,
 * its props need to match this schema (at least the required values):
 *
 * {
 *   isFormik?: boolean;
 *   name?: string;
 *   isInvalid?: boolean;
 *   message?: string;
 *   onChange: (e: React.ChangeEvent<any>) => void;
 *   onBlur?: (e: any) => void;
 *   value?: any;
 * }
 *
 */

const withFormikField = <T extends TFormikFieldRequiredProps>(Component: FC<T>): TWrappedField<T> => {
  const MemoComponent = memo(Component);
  const WrappedComponent = ({ isFormik = true, ...rest }: T) => (
    <FieldWrapper isFormik={isFormik} component={MemoComponent} {...rest} />
  );

  WrappedComponent.displayName = `${Component.displayName || "Component"}WithFormikField`;
  return WrappedComponent;
};

export default withFormikField;
