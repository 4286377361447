import { forwardRef } from "react";

import { Button as ButtonBase } from "@chakra-ui/react";

import { TButtonProps } from "./types";

const isLoadingStyleOverrides: any = {
  _disabled: {
    bg: null,
    _hover: {
      bg: null,
    },
  },
  _hover: {
    bg: null,
  },
};

const borderStyleOverrides = { border: "1px solid", borderColor: "grey.mid" };

const Button = forwardRef<HTMLButtonElement, TButtonProps>(
  (
    {
      label,
      size = "md",
      variant = "main",
      isDisabled = false,
      isOutlined = false,
      isLoading = false,
      shouldWrap = false,
      type = "button",
      ...rest
    },
    ref
  ) => (
    <ButtonBase
      size={size}
      variant={variant}
      isDisabled={isDisabled}
      isLoading={isLoading}
      type={type}
      ref={ref}
      whiteSpace={shouldWrap ? "normal" : "nowrap"}
      {...(isOutlined && borderStyleOverrides)}
      {...(isLoading && isLoadingStyleOverrides)}
      {...rest}
    >
      {label}
    </ButtonBase>
  )
);
Button.displayName = "Button";
export default Button;
