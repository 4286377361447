import { cloneElement, isValidElement, FC, ReactElement  } from "react";

import { chakra } from "@chakra-ui/react";

const LinkIcon: FC<{ children: ReactElement , marginEnd?: any, marginStart?: any }> = ({ children, ...rest }) => (
  <chakra.span flexShrink={0} {...rest}>
    {isValidElement(children)
      ? cloneElement(children, {
          "aria-hidden": true,
          focusable: false,
        } as any)
      : children}
  </chakra.span>
);
export default LinkIcon;
