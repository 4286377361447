import { FC } from "react";

import CookieConsentModal from "./CookieConsentModal";

type TProps = {
  isOpen: boolean;
  onClose: () => void;
  onChange: (value: string | boolean) => void;
  selectedCookies: string | boolean;
};

const SettingsCookieModal: FC<TProps> = ({ onClose, isOpen, onChange, selectedCookies }) => (
  <CookieConsentModal
    isOpen={isOpen}
    onClose={onClose}
    onChange={onChange}
    selectedCookies={selectedCookies}
    isCookieOptionsVisible
    id="settings-cookie-modal"
  />
);

export default SettingsCookieModal;
