/* eslint-disable no-underscore-dangle */
import { useRouter as useDefaultRouter, NextRouter } from "next/router";

import { useLocales } from "@lib/custom-locales";

const useRouter = (): NextRouter & { localeList: string[] | undefined } => {
  const data = useLocales();
  const locale = data?.customLocale;
  const localeList = data?.customLocales;
  const defaultLocale = localeList?.[0];
  if (!locale || !localeList || !defaultLocale) {
    throw new Error("No locale data found");
  }

  return {
    ...useDefaultRouter(),
    locale,
    defaultLocale,
    localeList,
  };
};
export default useRouter;
