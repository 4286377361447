import { FC } from "react";

import { VStack, Box, useBreakpointValue, Flex } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import dynamic from "next/dynamic";
import Head from "next/head";

import Message, { ContextMessage } from "@components/Message";
import { useFbPixel } from "@lib/fbPixel";
import { Desktop, Mobile } from "@lib/media";
import { RecaptchaProvider } from "@lib/recaptcha";
import colors from "@lib/theme/colors";
import { checkIfChatBotEnabled } from "@lib/webchat/utils/chatBotActions";
import { useFeatureData } from "@modules/features";
import { LAYOUT } from "@utils/constants";
import getEnv from "@utils/getEnv";

import Footer from "../Footer";
import Header from "../Header";
import { TLayout } from "./types";

const NextNProgress = dynamic(() => import("nextjs-progressbar"), { ssr: false });
const Webchat = dynamic(() => import("@lib/webchat"), { ssr: false });
const Scripts = dynamic(() => import("./Scripts"), { ssr: false });

const Layout: FC<TLayout> = ({
  children,
  domain,
  hasMobileFooter = true,
  hasTagManager = true,
  hasWebchat: isWithWebchat = true,
  hasMobileWebchat: isWithMobileWebchat = true,
  hasRecaptcha = false,
}) => {
  const { t } = useTranslation();

  useFbPixel();

  const isDesktop = useBreakpointValue({ base: false, md: true });
  const shouldShowFooter = isDesktop || hasMobileFooter;
  const features = useFeatureData();
  const isChatBotEnabled = checkIfChatBotEnabled(features);

  const hasWebchat = isChatBotEnabled && getEnv("NEXT_PUBLIC_USE_WEBCHAT") === "true" && !hasRecaptcha && isWithWebchat;

  return (
    <RecaptchaProvider isEnabled={hasRecaptcha} domain={domain}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1" />
        <noscript>
          <style>
            {`
              .noscript-hide {
                display: none;
              }
            `}
          </style>
        </noscript>
      </Head>
      <NextNProgress color={colors.red.lead} options={{ showSpinner: false }} />
      <VStack minH="100vh" justifyContent="flex-start" alignItems="stretch" spacing="0">
        <Box position="sticky" top="0" left="0" right="0" zIndex="100" background="white">
          <Header domain={domain} />
        </Box>

        <Flex direction="column" flexGrow={1} width="full">
          <noscript>
            <Message variant="danger" sx={{ mb: "0.5rem" }} textBoxWidth="71.25rem" justify="center" noScript>
              {t("common-errors-browser_error_message")}
            </Message>
          </noscript>
          <ContextMessage messageZone={LAYOUT.MESSAGE_ZONE_LAYOUT} />
          {children}
        </Flex>
        {shouldShowFooter && <Footer />}
      </VStack>
      {/**
       *
       * Place for additional scripts and script content containers.
       *
       */}
      {hasWebchat && (
        <Desktop>
          <Webchat />
        </Desktop>
      )}
      {hasWebchat && isWithMobileWebchat && (
        <Mobile>
          <Webchat />
        </Mobile>
      )}

      <Scripts hasTagManager={hasTagManager} />
    </RecaptchaProvider>
  );
};

export default Layout;
