import { FC } from "react";

import { chakra, HStack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";

import { LinkWrapper } from "@components/Link";
import { useAuth } from "@modules/auth";

import { LayoutContent } from "../Layout";
import { DpdLogo, DpdLogoLT } from "../Logo";
import LoggedInWidget from "./LoggedInWidget";
import LoggedOutWidget from "./LoggedOutWidget";

const headerTheme = {
  justifyContent: "space-between",
  alignItems: "center",
  height: "3.625rem",
  backgroundColor: "white",
  borderBottom: "1px solid",
  borderBottomColor: "grey.light",
};

type THeaderWrapperProps = {
  domain?: string;
};

const Header: FC<THeaderWrapperProps> = ({ domain }) => {
  const { isLoggedIn } = useAuth();
  const { t } = useTranslation();

  return (
    <LayoutContent>
      <HStack sx={headerTheme}>
        <LinkWrapper href="/">
          <chakra.a title={t("layout-header-logo_title")} id="logo-home" height="30">
            {domain === "lt" ? <DpdLogoLT /> : <DpdLogo />}
          </chakra.a>
        </LinkWrapper>
        {isLoggedIn ? <LoggedInWidget /> : <LoggedOutWidget />}
      </HStack>
    </LayoutContent>
  );
};

export default Header;
