import { FC } from "react";

import { TriangleDownIcon } from "@chakra-ui/icons";
import { Menu, MenuList, MenuButton, useMultiStyleConfig } from "@chakra-ui/react";

import { Typography } from "@components/common";
import Link from "@components/Link";

import { TDropdownProps } from "./types";
import getOptionValues from "./utils/getOptionValues";

const Dropdown: FC<TDropdownProps> = ({ id, options, value, href = "/" }) => {
  const styles = useMultiStyleConfig("Dropdown", {});
  const { selectedOption, remainingOptions } = getOptionValues(options, value);

  return (
    <Menu offset={[0, 0]} id={id}>
      <MenuButton sx={styles.select}>
        {selectedOption.label}
        <TriangleDownIcon height="0.375rem" width="0.5rem" />
      </MenuButton>
      <MenuList sx={styles.menu}>
        {remainingOptions.map(({ label, value: optionValue }) => (
          <Link
            key={`dropdown-${label}-${value}`}
            label={<Typography type="tiny">{label}</Typography>}
            sx={styles.item}
            variant="dark"
            name={`language-mobile-select-${optionValue}`}
            href={`/${optionValue}${href}`}
            target="_self"
            isExternal
          />
        ))}
      </MenuList>
    </Menu>
  );
};

export default Dropdown;
