import { FC, ReactNode } from "react";

import {
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  useMultiStyleConfig,
  Box,
  AccordionProps,
  SystemStyleObject,
  As,
} from "@chakra-ui/react";

type TExpandProps = {
  renderHeader: (isOpen: boolean) => ReactNode;
  defaultIsOpen?: boolean;
  variant?: "transparent" | "grey";
  id?: string;
  buttonSx?: SystemStyleObject;
  headerAs?: any;
  isOpen?: boolean;
  onChange?: (isOpen: boolean) => void;
} & Omit<AccordionProps, "onChange">;

const Expand: FC<TExpandProps> = ({
  renderHeader,
  children,
  defaultIsOpen,
  variant = "transparent",
  id,
  buttonSx,
  headerAs,
  isOpen,
  onChange,
  ...rest
}) => {
  const styles = useMultiStyleConfig("Expand", { variant });

  const checkRenderType = (node: ReactNode): ReactNode => {
    if (typeof node !== "string" && typeof node !== "number") {
      return node;
    }
    return <Box sx={styles.expandButton}>{node}</Box>;
  };
  return (
    <Accordion
      allowToggle
      reduceMotion
      defaultIndex={defaultIsOpen ? 0 : undefined}
      {...(typeof isOpen !== "undefined" && { index: isOpen ? 0 : -1 })}
      onChange={(expandedIndex) => onChange?.(expandedIndex as number > -1)}
      {...rest}
    >
      <AccordionItem sx={styles.expandItem} id={id}>
        {({ isExpanded }) => (
          <>
            <AccordionButton sx={{ ...styles.expandHeader, ...buttonSx }} as={headerAs}>
              {checkRenderType(renderHeader(isExpanded))}
            </AccordionButton>
            <AccordionPanel sx={styles.expandPanel}>{children}</AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};

export default Expand;
