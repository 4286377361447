import { FC, useRef } from "react";

import {
  Box,
  Drawer,
  DrawerOverlay,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  Flex,
  useMultiStyleConfig,
  useOutsideClick,
} from "@chakra-ui/react";
import { useTranslation } from "next-i18next";

import Link from "@components/Link";
import { Desktop, Mobile } from "@lib/media";

import { TExpandMenu } from "./types";

const emptyFunction = () => {};
const getKey = (id: string): string => `menu-drawer-item-${id}`;

const ExpandMenu: FC<TExpandMenu & { isDesktop: boolean }> = ({
  isOpen = false,
  onClose = emptyFunction,
  menu,
  footer,
  isDesktop,
  buttonRef,
}) => {
  const ref = useRef(null);
  const styles = useMultiStyleConfig("ExpandMenu", {});
  const { t } = useTranslation();
  useOutsideClick({
    ref,
    handler: (e) => {
      if (!isOpen || buttonRef?.current === e?.target || buttonRef?.current.contains(e?.target)) {
        return;
      }
      onClose();
    },
  });

  const filteredMenuItems = menu.filter(({ isDesktopOnly }) => !isDesktopOnly || isDesktop);
  return (
    <Drawer id={`expand-menu-${isDesktop ? "desktop" : "mobile"}`} isOpen={isOpen} placement="right" onClose={onClose}>
      {isDesktop && <DrawerOverlay />}
      <DrawerContent sx={styles.drawerMenu}>
        <DrawerBody sx={styles.drawerBody} ref={ref}>
          {isDesktop && (
            <Flex sx={styles.drawerHeaderWrapper}>
              <DrawerHeader sx={styles.drawerHeader}>{t("common-expand_menu-menu")}</DrawerHeader>
              <DrawerCloseButton sx={styles.closeButton} />
            </Flex>
          )}
          {filteredMenuItems.map(({ id, label, href }) => (
            <Link
              sx={{
                ...styles.drawerItem,
                borderBottom: "1px solid",
                borderColor: "grey.accents-2",
              }}
              key={getKey(id)}
              id={id}
              label={label}
              href={href}
              variant="dark"
            />
          ))}
          {footer && <Box sx={styles.drawerFooter}>{footer}</Box>}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

const ResponsiveExpandMenu: FC<TExpandMenu> = ({ ...props }) => (
  <>
    <Mobile>
      <ExpandMenu {...props} isDesktop={false} />
    </Mobile>
    <Desktop>
      <ExpandMenu {...props} isDesktop />
    </Desktop>
  </>
);
export default ResponsiveExpandMenu;
