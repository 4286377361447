/* eslint-disable max-len */

import { i18n } from "next-i18next";

import { TCookieOption } from "./types";

const t = (string: string): string => i18n?.t(string) || string;

const getConfig = (): TCookieOption[] => [
  {
    cookieGroupTitle: t("cookie_consent-settings-functional_title"),
    cookieGroupDescription: t("cookie_consent-settings-functional_description"),
    cookieGroupList: [
      {
        header: t("cookie_consent-google_analytics-title"),
        cookieList: [
          { name: "__cookie_consent", description: t("cookie_consent-google_analytics-description_1") },
          {
            name: "_gat_UA-107705204-1, _gat_UA-36710883-2",
            description: t("cookie_consent-google_analytics-description_2"),
          },
        ],
      },
      {
        header: t("cookie_consent-hotjar-title"),
        cookieList: [{ name: "_hjTLDTest", description: t("cookie_consent-hotjar-description_1") }],
      },
      {
        header: t("cookie_consent-framework-title"),
        cookieList: [
          { name: "laravel_session", description: t("cookie_consent-framework-description_1") },
          { name: "XSRF-TOKEN", description: t("cookie_consent-framework-description_2") },
        ],
      },
    ],
    isChecked: true,
    isDisabled: true,
    value: "1",
  },
  {
    cookieGroupTitle: t("cookie_consent-settings-statistics_title"),
    cookieGroupDescription: t("cookie_consent-settings-statistics_description"),
    cookieGroupList: [
      {
        header: t("cookie_consent-google_analytics-title"),
        cookieList: [
          { name: "_dc_gtm_UA-10525027-7", description: t("cookie_consent-google_analytics-description_3") },
          { name: "_ga, _gid", description: t("cookie_consent-google_analytics-description_4") },
        ],
      },
      {
        header: t("cookie_consent-hotjar-title"),
        cookieList: [
          { name: "_hjAbsoluteSessionInProgress", description: t("cookie_consent-hotjar-description_2") },
          { name: "_hjid", description: t("cookie_consent-hotjar-description_3") },
          {
            name: "_hjIncludeInSessionSample",
            description: t("cookie_consent-hotjar-description_4"),
          },
        ],
      },
    ],
    isChecked: false,
    isDisabled: false,
    value: "2",
  },
  {
    cookieGroupTitle: t("cookie_consent-settings-marketing_title"),
    cookieGroupDescription: t("cookie_consent-settings-marketing_description"),
    cookieGroupList: [
      {
        header: t("cookie_consent-google_doubleclick-title"),
        cookieList: [{ name: "IDE", description: t("cookie_consent-google_doubleclick-description_1") }],
      },
      {
        header: t("cookie_consent-facebook-title"),
        cookieList: [
          { name: "_fbp", description: t("cookie_consent-facebook-description_1") },
          { name: "fr", description: t("cookie_consent-facebook-description_2") },
        ],
      },
      {
        header: t("cookie_consent-google_tag_manager-title"),
        cookieList: [{ name: "_gcl_au", description: t("cookie_consent-google_tag_manager-description_1") }],
      },
    ],
    isChecked: false,
    isDisabled: false,
    value: "3",
  },
];

export default getConfig;
