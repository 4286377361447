import { FC, useCallback, MouseEvent } from "react";

import { Flex, HStack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";

import Link from "@components/Link";
import { useRouter } from "@lib/router";
import { useAuth } from "@modules/auth";

import useLocaleList from "../../hooks/useLocaleList";

const HeaderMenuFooter: FC = () => {
  const { t } = useTranslation();
  const { availableLanguages, localeValue } = useLocaleList();
  const { asPath } = useRouter();

  const { logout } = useAuth();

  const handleLogoutClick = useCallback(
    (evt: MouseEvent) => {
      evt.preventDefault();
      logout();
    },
    [logout]
  );

  return (
    <Flex justify="space-between">
      <HStack spacing="1.5rem">
        {availableLanguages.map(({ label, value }) => (
          <Link
            id={`expand-menu-language-${value}`}
            label={label.toUpperCase()}
            key={`menu-footer-locale-${label}-${value}`}
            href={`/${value}${asPath}`}
            fontWeight={value === localeValue ? "normal" : "light"}
            variant={value === localeValue ? "red" : "dark"}
            isExternal
            target="_self"
          />
        ))}
      </HStack>
      <Link
        href="#"
        id="expand-menu-logout"
        variant="red"
        fontWeight="300"
        label={t("user_menu-logout")}
        onClick={handleLogoutClick}
      />
    </Flex>
  );
};

export default HeaderMenuFooter;
