import { FC, useRef, useState } from "react";

import { HStack } from "@chakra-ui/react";
import { TFunction, useTranslation } from "next-i18next";

import { IconButton } from "@components/buttons";
import ExpandMenu from "@components/ExpandMenu";
import Icon from "@components/Icon";
import { LinkWrapper } from "@components/Link";
import { ROUTES } from "@utils/constants";

import HeaderMenuFooter from "./HeaderMenuFooter";

const createUserMenuItems = (t: TFunction) => [
  { id: "expand-menu-order", href: ROUTES.ORDER, label: t("user_menu-send_parcel") },
  { id: "expand-menu-shipments", href: ROUTES.SHIPMENTS, label: t("user_menu-shipments") },
  { id: "expand-menu-profile", href: ROUTES.PROFILE_MY_ADDRESSES, label: t("user_menu-your_profile") },
  { id: "expand-menu-address-book", href: ROUTES.USER_DATA_ADDRESS_BOOK, label: t("user_menu-address_book") },
  { id: "expand-menu-invoices", href: ROUTES.USER_DATA_INVOICES, label: t("user_menu-invoices") },
  { id: "expand-menu-faq", href: ROUTES.FAQ, label: t("user_menu-faq") },
];

const LoggedInWidget: FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const buttonRef = useRef(null);
  const { t } = useTranslation();
  const ProfileIcon = <Icon icon="profile" size="1.5rem" />;
  const MenuIcon = <Icon icon="menuBurger" size="1.5rem" />;
  const CloseIcon = <Icon icon="closeRed" size="1.5rem" />;

  return (
    <HStack spacing="0">
      <HStack spacing="0.625rem">
        <LinkWrapper href={ROUTES.PROFILE_MY_ADDRESSES}>
          <IconButton
            id="profile-button"
            variant="grey-light"
            size="md"
            icon={ProfileIcon}
            title={t("layout-header-profile")}
            as="a"
          />
        </LinkWrapper>
        <IconButton
          id="expand-menu-button"
          variant="transparent"
          size="md"
          ref={buttonRef}
          title={isMenuOpen ? t("layout-header-close_menu") : t("layout-header-open_menu")}
          icon={isMenuOpen ? CloseIcon : MenuIcon}
          onClick={() => setIsMenuOpen((isOpen) => !isOpen)}
        />
      </HStack>
      <ExpandMenu
        menu={createUserMenuItems(t)}
        footer={<HeaderMenuFooter />}
        buttonRef={buttonRef}
        isOpen={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
      />
    </HStack>
  );
};

export default LoggedInWidget;
