/**
 *
 * Get an array of cookie values that are always checked.
 *
 */

import getConfig from "../config";
import { TCookieOption } from "../types";
import { getConsentCookie } from "./cookieActions";

export const getDefaultCheckedCookies = (cookieOptions: TCookieOption[]): string[] => {
  const currentCookie = getConsentCookie();

  return cookieOptions.reduce<string[]>((acc, { isChecked, value }) => {
    if (isChecked || currentCookie?.toString() === value || currentCookie?.toString() === "true") {
      acc.push(value);
    }
    return acc;
  }, []);
};

/**
 *
 * Get 'true' if all cookies are checked, get 'false' if only the default cookies are set.
 * Else: get a string with a single or multiple group values (if cookie config grows that could become a possibility).
 *
 */

export const getSelectedCookies = (cookieOptions: TCookieOption[], selectedCookieState: string[]): boolean | string => {
  if (selectedCookieState.length === 3) {
    return true;
  }

  if (selectedCookieState.length === 1) {
    return false;
  }

  const defaultChecked = cookieOptions.find((cookie) => cookie.isChecked);

  const notCheckedAsDefault = selectedCookieState.filter((cookie) => cookie !== defaultChecked?.value);

  return notCheckedAsDefault.join(" ");
};

/**
 *
 * Get a state update callback that adds or removes value from a list of values.
 *
 */

export const createCookieStateUpdate =
  (value: string | boolean) =>
  (cookieState: string[]): string[] => {
    const cookieConfig = getConfig();
    if (value === true) {
      return cookieConfig.map((item) => item.value);
    }
    if (value === false) {
      return cookieConfig.reduce<string[]>((acc, item) => {
        if (item.isChecked) {
          acc.push(item.value);
        }
        return acc;
      }, []);
    }
    if (cookieState.includes(value)) {
      return cookieState.filter((cookie) => cookie !== value);
    }
    const newState = [...cookieState];
    newState.push(value);
    return newState;
  };
