import { useState } from "react";

import { TCookieGroupReturns, TCookieOption } from "../types";
import { createCookieStateUpdate, getDefaultCheckedCookies, getSelectedCookies } from "../utils/cookieGroupActions";

const useCookieGroups = (cookieOptions: TCookieOption[]): TCookieGroupReturns => {
  const defaultChecked = getDefaultCheckedCookies(cookieOptions);

  const [selectedCookieState, setSelectedCookieState] = useState(defaultChecked);

  const onChange = (value: string | boolean) => {
    setSelectedCookieState(createCookieStateUpdate(value));
  };

  const selectedCookies = getSelectedCookies(cookieOptions, selectedCookieState);

  return { onChange, selectedCookies };
};

export default useCookieGroups;
