import { forwardRef } from "react";

import { Link as LinkBase, chakra } from "@chakra-ui/react";

import LinkIcon from "./LinkIcon";
import LinkWrapper from "./LinkWrapper";
import { TLinkProps } from "./types";

const Link = forwardRef<HTMLAnchorElement, TLinkProps>(
  (
    {
      label,
      href,
      locale,
      shallow,
      as,
      replace,
      scroll,
      leftIcon,
      rightIcon,
      isUnderlined = false,
      iconSpacing = "0.5rem",
      size = "md",
      variant = "dark",
      tabIndex = 0,
      isExternal = false,
      target,
      isInline = false,
      ...rest
    },
    ref
  ) => (
    <LinkWrapper {...{ href, locale, shallow, as, replace, scroll, isExternal, target }}>
      <LinkBase
        size={size}
        variant={variant}
        {...(isUnderlined && { textDecoration: "underline" })}
        {...(isInline && { display: "inline" })}
        tabIndex={tabIndex}
        ref={ref}
        {...rest}
      >
        <chakra.span display={isInline ? "inline" : "inline-flex"} alignItems="center">
          {leftIcon && <LinkIcon marginEnd={iconSpacing}>{leftIcon}</LinkIcon>}
          {label}
          {rightIcon && <LinkIcon marginStart={iconSpacing}>{rightIcon}</LinkIcon>}
        </chakra.span>
      </LinkBase>
    </LinkWrapper>
  )
);

Link.displayName = "Link";
export default Link;
