import { FC } from "react";

import {
  Modal as ModalBase,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useMultiStyleConfig,
  ModalFooter,
  Flex,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useTranslation } from "next-i18next";

import { Button } from "@components/buttons";
import { Typography } from "@components/common";

import { TModalProps } from "./types";
import {
  getModalContentStyles,
  getModalHeaderStyles,
  getModalBodyStyles,
  getModalFooterStyles,
  getModalHeaderWrapperStyles,
} from "./utils/getStyles";

const callEmpty = () => {};

const Modal: FC<TModalProps> = ({
  children,
  onClose = callEmpty,
  onReject,
  onSubmit,
  onUtilityClick = callEmpty,
  headerText,
  width,
  submitLabel,
  rejectLabel,
  utilityLabel,
  isOpen = false,
  hasCloseButton = true,
  hasSubmitButton = false,
  hasRejectButton = false,
  hasUtilityButton = false,
  isSubmitDisabled = false,
  isOverlayDisabled = false,
  isCentered = false,
  hasHeader = true,
  trapFocus = false,
  id,
}) => {
  const variant = useBreakpointValue({ base: "mobile", md: "desktop" });
  const styles = useMultiStyleConfig("Modal", { variant });
  const { t } = useTranslation();
  const handleClose = hasCloseButton ? onClose : callEmpty;

  return (
    <ModalBase
      motionPreset="scale"
      onClose={handleClose}
      isOpen={isOpen}
      id={id}
      closeOnOverlayClick={!isOverlayDisabled}
      trapFocus={trapFocus}
    >
      <ModalOverlay sx={styles.overlay} />

      <ModalContent sx={getModalContentStyles(styles, width)} ref={(el) => el?.focus()}>
        {(hasCloseButton || headerText) && (
          <Flex sx={getModalHeaderWrapperStyles(styles, hasHeader)}>
            {headerText && (
              <ModalHeader sx={getModalHeaderStyles(styles, isCentered, hasCloseButton)}>
                <Typography sx={styles.headerText}>{headerText}</Typography>
              </ModalHeader>
            )}
            {hasCloseButton && <ModalCloseButton sx={styles.button} id="modal-exit-button" />}
          </Flex>
        )}

        <ModalBody sx={getModalBodyStyles(styles, isCentered, hasCloseButton, hasHeader)}>{children}</ModalBody>
        {(hasSubmitButton || hasRejectButton) && (
          <ModalFooter sx={getModalFooterStyles(styles, hasSubmitButton, hasRejectButton, isCentered)}>
            {hasRejectButton && (
              <Button
                id={(id && `${id}-cancel-button`) || "modal-cancel-button"}
                onClick={onReject || onClose}
                variant="secondary"
                label={rejectLabel || t("common-modal-cancel")}
                sx={styles.footerButton}
              />
            )}
            {hasUtilityButton && (
              <Button
                id={(id && `${id}-utility-button`) || "modal-utility-button"}
                onClick={onUtilityClick}
                variant="secondary"
                label={utilityLabel || t("common-modal-utilty")}
                sx={styles.footerButton}
              />
            )}
            {hasSubmitButton && (
              <Button
                id={(id && `${id}-submit-button`) || "modal-submit-button"}
                type="submit"
                label={submitLabel || t("common-modal-submit")}
                onClick={onSubmit}
                sx={styles.footerButton}
                isDisabled={isSubmitDisabled}
              />
            )}
          </ModalFooter>
        )}
      </ModalContent>
    </ModalBase>
  );
};

export default Modal;
