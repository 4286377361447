import { FC } from "react";

import { Box, HStack } from "@chakra-ui/react";

import { Typography } from "@components/common";

import { TCookieGroupList } from "../types";

const listItemStyle = { flexBasis: "50%", flexGrow: 0, wordBreak: "break-all", fontWeight: "light" };
const listHeaderStyle = { fontWeight: "bold", mb: "0.3125rem" };

const CookieConsentGroupList: FC<TCookieGroupList> = ({ header, cookieList }) => (
  <Box mx="0.625rem" fontSize="0.875rem">
    <Typography sx={listHeaderStyle}>{header}</Typography>
    {cookieList.map(({ name, description }) => (
      <HStack mx="0.625rem" key={name} alignItems="start" mb="0.3125rem">
        <Typography sx={listItemStyle}>{name}</Typography>
        <Typography flexBasis="50%">{description}</Typography>
      </HStack>
    ))}
  </Box>
);

export default CookieConsentGroupList;
