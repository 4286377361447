import { FC } from "react";

import { FormLabel, useMultiStyleConfig, FormLabelProps } from "@chakra-ui/react";

const FormFieldLabel: FC<{ label: string | undefined } & FormLabelProps> = ({ label, sx, ...props }) => {
  const styles = useMultiStyleConfig("Common", {});
  return (
    <>
      {label && (
        <FormLabel sx={{ ...styles.formFieldLabel, ...sx }} {...props}>
          {label}
        </FormLabel>
      )}
    </>
  );
};

export default FormFieldLabel;
