import { useMemo } from "react";

import { useTranslation } from "next-i18next";

import { useRouter } from "@lib/router";
import { LOCALE } from "@utils/constants";

type TLocaleListReturns = {
  availableLanguages: {
    label: string;
    value: string;
  }[];
  localeValue: string;
};

const useLocaleList = (): TLocaleListReturns => {
  const { localeList, locale: currentLocale, defaultLocale } = useRouter();
  const { t } = useTranslation();

  const availableLanguages = useMemo(
    () =>
      localeList?.map((locale) => ({
        label: t(`locale-${locale.toLowerCase()}`),
        value: locale.toLowerCase(),
      })) ?? [],
    [localeList, t]
  );

  const localeValue = currentLocale ?? defaultLocale ?? LOCALE.FALLBACK_LOCALE;

  return { availableLanguages, localeValue };
};

export default useLocaleList;
